import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { id: "projects" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "icons links" }
const _hoisted_9 = ["href", "download"]
const _hoisted_10 = { class: "icons tech" }
const _hoisted_11 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectDetails = _resolveComponent("ProjectDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_TransitionGroup, {
        name: "table",
        key: _ctx.$route.path
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentProjects, (app, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: app.name,
              style: _normalizeStyle({
            borderBottomColor: _ctx.rowsEnteringDirection
              ? 'transparent'
              : 'var(--e)',
            opacity: _ctx.rowsEnteringDirection ? 0 : 1,
            animation: _ctx.rowsEnteringDirection
              ? `rows-enter-${_ctx.rowsEnteringDirection} 600ms ${i * 50}ms forwards`
              : `none`,
          })
            }, [
              _createElementVNode("div", {
                class: "app-name",
                onClick: ($event: any) => (_ctx.modalOpen = !!(_ctx.modalProject = app))
              }, [
                _createElementVNode("img", {
                  src: require(`@/assets/webp-app-logos/${app.img}.webp`),
                  class: _normalizeClass({ invert: app.img == 'My Developer Portfolio' }),
                  alt: "logo"
                }, null, 10, _hoisted_3),
                _createTextVNode(" " + _toDisplayString(app.name) + " ", 1),
                (_openBlock(), _createBlock(_resolveDynamicComponent('InfoSVG')))
              ], 8, _hoisted_2),
              _createElementVNode("div", null, _toDisplayString(app.created.toLocaleDateString()), 1),
              _createVNode(_Transition, {
                name: "reveal",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (app.updated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(app.updated.toLocaleDateString()), 1))
                    : (_ctx.$isBrowserApp)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent('LoadingSVG')))
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_Transition, {
                name: "reveal",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (app.weeklyUsers)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.formatCount(app.weeklyUsers)), 1))
                    : (_ctx.$isBrowserApp)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent('LoadingSVG')))
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(app.links, (value, key) => {
                  return (_openBlock(), _createElementBlock("a", {
                    key: key,
                    href: value,
                    class: _normalizeClass({ invert: /(github|home|download)/.test(key) }),
                    target: "_blank",
                    download: value?.endsWith('exe') ? '' : null
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(key + 'SVG'), { unmodified: "" }))
                  ], 10, _hoisted_9))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(app.tech, (tech) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: tech,
                    title: tech
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(tech + 'SVG'), { unmodified: "" }))
                  ], 8, _hoisted_11))
                }), 128))
              ])
            ], 4))
          }), 128))
        ]),
        _: 1
      }))
    ]),
    _createVNode(_component_ProjectDetails, {
      modalOpen: _ctx.modalOpen,
      modalProject: _ctx.modalProject,
      currentProjects: _ctx.currentProjects,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen = false)),
      onSwipe: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalProject = _ctx.currentProjects[$event]))
    }, null, 8, ["modalOpen", "modalProject", "currentProjects"])
  ]))
}